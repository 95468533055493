















































































































































import Table from "@monorepo/uikit/src/components/common/Table.vue";
import TableHeader from "@monorepo/uikit/src/components/tableViews/TableHeader.vue";
import Filters from "@monorepo/uikit/src/components/common/Filters.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import InfiniteScroll from "@monorepo/uikit/src/components/tableViews/InfiniteScroll.vue";
import ScrollPanel from "@monorepo/uikit/src/components/tableViews/ScrollPanel.vue";
import {
  totalStorageUnitsHeaders,
  approvedEPCHeaders,
  storedAfterLimitHeaders,
} from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/tableHeaders";
import { viewTitle, viewUniqKey } from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/viewTitle";
import { defineComponent, ref } from "@vue/composition-api";
import { module as PassportArchiveReportView } from "@monorepo/reports/src/store/modules/passportArchiveReportView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { mapActions, mapGetters, Module } from "vuex";
import { breadcrumbs } from "@monorepo/reports/src/views/PassportArchiveReportView/constants/breadcrumbs";
import TableSettings from "@monorepo/uikit/src/components/tableViews/TableSettings.vue";
import ExportButton from "@monorepo/uikit/src/components/tableViews/ExportButton.vue";
import useSetStartFilters from "@monorepo/utils/src/composables/useSetStartFilters";
import useInitEducationPanel from "@monorepo/utils/src/composables/useInitEducationPanel";
import useOpenHistoryMode from "@monorepo/utils/src/composables/useOpenHistoryMode";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { isAuthorityExist } from "@monorepo/utils/src/utils/isAuthorityExist";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";
import { convertApiItemToUi } from "@monorepo/reports/src/views/PassportArchiveReportView/utils/convertApiItemToUi";
import { filtersElements } from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/filtersElements";
import { currentFilters as setCurrentFilters } from "@monorepo/reports/src/views/PassportArchiveReportView/utils/defaultCurrentFilters";
import { fields } from "@monorepo/utils/src/variables/projectsData/passportArchiveReport/fields";
import useAsyncExport from "@monorepo/utils/src/composables/useAsyncExport";

const SECTION_NAME = "passportArchiveReportView";

export default defineComponent({
  name: "PassportArchiveReportView",
  components: {
    TableSettings,
    ExportButton,
    InfiniteScroll,
    Table,
    Filters,
    ScrollPanel,
    TableHeader,
    Breadcrumbs,
  },
  data() {
    return {
      breadcrumbs,
      viewTitle,
      viewUniqKey,
      educationIds,
      totalStorageUnitsHeaders,
      approvedEPCHeaders,
      storedAfterLimitHeaders,
      convertApiItemToUi,
      fields,
      filtersElements,
      setCurrentFilters,
      autocompleteFiltersKeys: [fields.FILTER_ARCHIVE],
      isDataLoading: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(SECTION_NAME, ["openedId", "infiniteId", "isOpenFilters", "data", "totalLength", "isTableLoading"]),
    isShowExport(): boolean {
      return isAuthorityExist(this.user, authorities.REPORT_EXPORT);
    },
  },
  methods: {
    ...mapActions(SECTION_NAME, ["getEventList", "getExportData", "addOpenedId", "setIsOpenFilters"]),
    goToHistoricalNotes() {
      this.$router.push("/inventory/historical-notes");
    },
    changeCurrentFiltersBeforeAdd(filters: ReturnType<typeof setCurrentFilters>) {
      if (filters[fields.FILTER_START_YEAR] && !filters[fields.FILTER_END_YEAR]) {
        filters[fields.FILTER_END_YEAR] = new Date().getFullYear().toString();
      }
    },
  },
  watch: {
    async infiniteId(nv) {
      try {
        this.isDataLoading = true;
        await this.getEventList();
      } catch (e) {
        console.error(e);
      } finally {
        this.isDataLoading = false;
      }
    },
  },
  setup(props, { root }) {
    const filtersLoaded = ref(false);
    const sectionName = ref(SECTION_NAME); // sectionName === store module name

    useInitTableStoreModule(root, SECTION_NAME, PassportArchiveReportView as Module<unknown, unknown>);
    useOpenHistoryMode(root, SECTION_NAME);
    useSetStartFilters(root, SECTION_NAME, ["id"]).then(() => {
      useInitEducationPanel(root);
      filtersLoaded.value = true;
    });
    useAsyncExport(root, SECTION_NAME);

    return {
      filtersLoaded,
      sectionName,
    };
  },
});
