import { fields } from "./fields";
import { educationIds } from "@monorepo/utils/src/variables/educationIds";

export const filtersElements = [
  {
    title: "Период",
    tooltip: "Период",
    dateValues: [fields.FILTER_START_YEAR, fields.FILTER_END_YEAR],
    type: "yearPeriod",
    isRequired: false,
    isCustomWidth: true,
    width: 250,
  },
  {
    value: fields.FILTER_ARCHIVE,
    title: "Архив",
    tooltip: "Выбор из списка с предиктивным вводом",
    type: "autocomplete",
    showSelectedKey: "name",
    autocompleteActionName: "getArchiveAutocompleteByCode",
    width: 250,
    educationId: educationIds.listViewSettings,
  },
];
