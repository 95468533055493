import { v4 as uuid } from "uuid";
// import { headerCheckboxObject } from "@monorepo/utils/src/variables/headerCheckboxValue";
import { fields } from "./fields";

const commonStartHeaders = [
  {
    text: "Показатели",
    tooltip: "Формируются две строки: Постоянного хранения, По личному составу",
    defaultWidth: 120,
    value: fields.TABLE_INDICATORS,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Код строки",
    tooltip: "Для Постоянного хранения = 201, для По личному составу = 202",
    defaultWidth: 120,
    value: fields.TABLE_STRING_CODE,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];

export const totalStorageUnitsHeaders = [
  ...commonStartHeaders,
  {
    text: "Всего",
    tooltip: "Всего поступивших ЭАД",
    defaultWidth: 200,
    value: fields.TABLE_TOTAL_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Начальная дата",
    tooltip: "Дата поступления первого ЭАД",
    defaultWidth: 316,
    value: fields.TABLE_START_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Конечная дата",
    tooltip: "Дата поступления последнего ЭАД",
    defaultWidth: 240,
    value: fields.TABLE_END_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];

export const approvedEPCHeaders = [
  ...commonStartHeaders,
  {
    text: "Утвержденные ЭПК архивного учреждения",
    tooltip: "Количество ЭАД, внесённых в описи дел, утверждённые ЭПК архивного учреждения",
    defaultWidth: 300,
    value: fields.TABLE_TOTAL_NUMBER,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Начальная дата",
    tooltip: "Дата поступления первого ЭАД",
    defaultWidth: 316,
    value: fields.TABLE_START_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Конечная дата",
    tooltip: "Дата поступления последнего ЭАД",
    defaultWidth: 240,
    value: fields.TABLE_END_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];

export const storedAfterLimitHeaders = [
  ...commonStartHeaders,
  {
    text: "Хранится сверх установленного срока",
    tooltip: "Количество ЭАД, хранящихся сверх установленного срока. Для ЭАД По личному составу не заполняется",
    defaultWidth: 200,
    value: fields.TABLE_STORED_AFTER_LIMIT,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
  {
    text: "Образуется в год дел",
    tooltip: "Количество ЭАД, поступающих на хранение в год",
    defaultWidth: 190,
    value: fields.TABLE_TOTAL_IN_YEAR,
    isSorted: true,
    isHandle: false,
    id: uuid(),
  },
];
